import axios from 'axios'

let baseUrl = `${location.protocol}//api.local/v2`
switch (location.hostname) {
  case 'dalmatian-dev.reunionmarketing.com':
    baseUrl = `${location.protocol}//api-dev.reunionmarketing.com/v2`
    break
  case 'dalmatian-uat.reunionmarketing.com':
    baseUrl = `${location.protocol}//api-uat.reunionmarketing.com/v2`
    break
  case 'dalmatian.reunionmarketing.com':
    baseUrl = `${location.protocol}//api.reunionmarketing.com/v2`
    break
}

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  }
})

export default instance
