<template>
  <div class="avatar-img-wrapper" :style="avatarSizeStyle">

    <default-avatar v-if="userId>0 && isDefaultImage"
            :username="userName"
            :style="avatarStyle"
            :size="avatarSize"
            :rounded="false"/>

    <img v-else :alt="userName" :src="userAvatar" @error="onImgError" class="avatar" :style="avatarInlineStyle"/>
  </div>
</template>

<script>
import DefaultAvatar from './DefaultAvatar'

export default {
  name: 'InlineAvatar',
  components: { DefaultAvatar },
  props: {
    userId: {
      type: [String, Number]
    },
    userName: {
      type: String
    },
    avatar: {
      type: String,
      default: undefined
    },
    inlineStyle: {
      type: String,
      default: 'border-radius: 0.1rem; display: block;'
    },
    size: {
      type: [String, Number],
      default: 25
    }
  },
  data () {
    return {
      // Set up default data values
      outputMime: 'image/jpeg',
      uploadMimeTypes: 'image/png, image/gif, image/jpeg',
      uploadLabels: {
        submit: 'Save',
        cancel: 'Cancel'
      },
      defaultAvatar: this.imgUrl('default-avatar.png'),
      isDefaultImage: false,
      imgError: false
    }
  },
  computed: {
    avatarSize: function () {
      return this.size || 25
    },
    avatarStyle: function () {
      return this.inlineStyle || 'border-radius: 0.1rem; display: block;'
    },
    avatarSizeStyle: function () {
      return 'width: ' + this.avatarSize + 'px; height: ' + this.avatarSize + 'px;'
    },
    avatarInlineStyle: function () {
      return this.avatarStyle + 'width: ' + this.avatarSize + 'px; height: ' + this.avatarSize + 'px;'
    },
    userAvatar: {
      get () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isDefaultImage = this.avatar && this.avatar.includes('default')
        return this.avatar && !this.imgError ? this.avatar : this.defaultAvatar
      },
      set (newAvatar) {
        this.isDefaultImage = newAvatar && newAvatar.includes('default')
        return newAvatar && !this.imgError ? newAvatar : this.defaultAvatar
      }
    }
  },
  watch: {
    avatar: {
      // watch for props to load
      immediate: true,
      handler (newAvatar) {
        this.userAvatar = newAvatar
      }
    }
  },
  methods: {
    onImgError () {
      this.imgError = true
      this.userAvatar = this.defaultAvatar
    }
  }
}
</script>

<style lang="scss">

</style>
