const SpecialsManager = () => import('@/views/Specials/SpecialsManager')

const specialsToolRoutes = [
  {
    path: '/specials-manager',
    name: 'specials-manager',
    component: SpecialsManager,
    meta: {
      requiresAuth: true,
      module: 'specials-tool'
    }
  }
]

export default specialsToolRoutes
