import _ from 'lodash'

export default {
  install: function (Vue) {
    Vue.mixin({
      computed: {
        _: function () {
          return _
        }
      }
    })
  }
}
