const FbAdCampaigns = () => import('@/views/FbDdTool/FbAdCampaigns')

const fbDdToolRoutes = [
  {
    path: '/fb-ad-campaigns',
    name: 'facebook-ad-campaigns',
    component: FbAdCampaigns,
    meta: {
      requiresAuth: true,
      module: 'fb-dd-tool'
    }
  },
  {
    path: '/fb-ad-campaigns/:id',
    name: 'facebook-ad-campaign',
    component: FbAdCampaigns,
    meta: {
      requiresAuth: true,
      module: 'fb-dd-tool'
    }
  }
]

export default fbDdToolRoutes
