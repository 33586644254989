const ProspectProfile = () => import('../views/Prospects/ProspectProfile')
const ProspectsIndex = () => import('../views/Prospects/ProspectsIndex')
const ProspectOnboardingBoard = () => import('../views/Prospects/ProspectOnboardingBoard')
const ProspectOnboardingProfile = () => import('../views/Prospects/ProspectOnboardingProfile')
const ProspectDelete = () => import('../views/Prospects/ProspectDelete')

const prospectsRoutes = [
  {
    path: '/prospects/new',
    name: 'prospects.new',
    component: ProspectProfile,
    meta: {
      requiresAuth: true,
      module: 'prospects'
    }
  },
  {
    path: '/prospects/:id',
    name: 'prospect.edit',
    component: ProspectProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/prospects/index',
    name: 'prospect.index',
    component: ProspectsIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/prospects/delete/:id',
    name: 'prospect.delete',
    component: ProspectDelete,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboarding/prospects',
    name: 'onboarding.prospects',
    component: ProspectOnboardingBoard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboarding/prospects/:id/',
    name: 'onboarding-prospect-profile',
    component: ProspectOnboardingProfile,
    meta: {
      requiresAuth: true
    }
  }
]

export default prospectsRoutes
