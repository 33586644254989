const ClientsIndex = () => import('@/views/Clients/ClientsIndex')
const ClientRequestsCalculator = () => import('@/views/Clients/ClientRequestsCalculator')

const clientsRoutes = [
  {
    path: '/clients/index',
    name: 'clients.index',
    component: ClientsIndex,
    meta: {
      requiresAuth: true,
      module: 'clients'
    }
  },
  {
    path: '/clients/requests-priority-calculator',
    name: 'clients.requests-priority-calculator',
    component: ClientRequestsCalculator,
    meta: {
      requiresAuth: true,
      module: 'clients'
    }
  }
]

export default clientsRoutes
