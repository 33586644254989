import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios'
import lodash from './mixins/lodash'
import GlobalMixin from './mixins/GlobalMixin'
import Swal from 'sweetalert2'
import VueSlugify from 'vue-slugify'
import { Calendar, DatePicker } from 'v-calendar'
import 'bootstrap' // bootstrap.js
import tooltip from './directives/tooltip.js'
import GAuth from 'vue3-google-oauth2'

const app = createApp(App)

// add global mixins, plugins, components and directives
app.mixin(lodash)
app.mixin(GlobalMixin)
app.use(VueSlugify)
app.directive('tooltip', tooltip)

const gAuthOptions = {
  clientId: '833691541538-8mcidbbrf6hn3vq3v0ef85e1hb6gccg0.apps.googleusercontent.com',
  scope: 'profile email openid https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/plus.business.manage',
  prompt: 'consent' // needs to be consent to get refresh token
}
app.use(GAuth, gAuthOptions)

// set global button styles
const SwalGlobalConfig = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success mx-1',
    cancelButton: 'btn btn-danger mx-1'
  },
  buttonsStyling: false
})

app.component('confirm-button', require('./components/ConfirmButton').default)
app.component('loading-button', require('./components/LoadingButton').default)
app.component('loading-icon', require('./components/LoadingIcon').default)
app.component('inline-message', require('./components/InlineMessage').default)
app.component('modal', require('./components/Modal').default)
app.component('p-check', require('./components/PrettyCheckbox').default)
app.component('p-radio', require('./components/PrettyRadio').default)
app.component('v-calendar', Calendar)
app.component('v-date-picker', DatePicker)

// Intercept all 401 Unauthenticated errors and return user to login
axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  // Log user out & return to login screen when user is no longer authenticated
  if (error.response && error.response.status === 401) {
    store.dispatch('noAuthLogout')
      .then(() => {
        // Skip repetitive 401 route changes for multiple calls on same page
        if (router.currentRoute.name !== 'auth.login') {
          router.push('/auth/login').catch(() => {
          })
        }
      })
  }
  return Promise.reject(error)
})

// console.log(store.getters.apiToken)
if (store.getters.apiToken !== '') {
  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${store.getters.apiToken}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

app.config.unwrapInjectedRef = true
app.config.globalProperties.$swal = SwalGlobalConfig
app.config.globalProperties.$api = axios
app.use(store).use(router).mount('#app')
