const Content = () => import('../views/ContentManagement/Content')
const FixedOpsContent = () => import('../views/ContentManagement/FixedOpsContent')
const ClientContentPageContent = () => import('../views/ClientContents/ClientContentPageContent')

const contentManagementRoutes = [
  {
    path: '/content',
    name: 'content',
    component: Content,
    meta: {
      requiresAuth: true,
      module: 'content'
    },
    redirect: '/content/mcp-content'
  },
  {
    path: '/content/mcp-content',
    name: 'mcp-content',
    component: Content,
    meta: {
      requiresAuth: true,
      module: 'content'
    }
  },
  {
    path: '/content/mob-content',
    name: 'mob-content',
    component: Content,
    meta: {
      requiresAuth: true,
      module: 'content'
    }
  },
  {
    path: '/content/used-mcp-content',
    name: 'used-mcp-content',
    component: Content,
    meta: {
      requiresAuth: true,
      module: 'content'
    }
  },
  {
    path: '/content/fixed-ops-content',
    name: 'fixed-ops-content',
    component: FixedOpsContent,
    meta: {
      requiresAuth: true,
      module: 'content'
    }
  },
  {
    path: '/content/client-content',
    name: 'client-content-page-contents',
    component: ClientContentPageContent,
    meta: {
      requiresAuth: true,
      module: 'content'
    }
  }
]

export default contentManagementRoutes
