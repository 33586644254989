<template>
  <div v-show="displayMessage && showMessage" class="m-2">
    <div :class="alertClass" role="alert">
      <div :class="Array.isArray(displayMessage)?'d-flex-column':'d-flex'">
        <template v-if="Array.isArray(displayMessage)">
          <template  v-for="message in displayMessage" :key="message">
            <div class="me-auto"><i :class="iconClass"></i>&nbsp;<span v-html="message"></span></div>
            <button v-show="dismissible" type="button" class="btn-close" @click.prevent="close" aria-label="Close"></button>
          </template>
        </template>
        <template v-else>
        <span class="me-auto"><i :class="iconClass"></i>&nbsp;<span v-html="displayMessage"></span></span>
          <button v-show="dismissible" type="button" class="btn-close" @click.prevent="close" aria-label="Close"></button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InlineMessage',
  inheritAttrs: false,
  emits: ['update:message'],
  props: {
    message: {
      type: [String, Array],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    transient: {
      type: Boolean,
      default: false
    },
    time: {
      type: [String, Number],
      default: 6000
    }
  },
  data () {
    return {
      allowedTypes: [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark'
      ],
      displayMessage: this.message,
      showMessage: true,
      timeout: null
    }
  },
  computed: {
    messageType: {
      get () {
        return (this.allowedTypes.includes(this.type)) ? this.type : 'primary'
      },
      set (type) {
        return (this.allowedTypes.includes(type)) ? type : 'primary'
      }
    },
    alertClass: function () {
      return 'alert alert-' + this.messageType +
        (this.messageType === 'light' ? ' border' : '') +
        (this.dismissible ? ' alert-dismissible fade show' : '')
    },
    iconClass: function () {
      if (this.icon) {
        return (this.icon === 'none') ? '' : this.icon
      }
      switch (this.messageType) {
        case 'success':
          return 'fas fa-check-circle'
        case 'danger':
          return 'fas fa-exclamation-circle'
        case 'warning':
          return 'fas fa-exclamation-triangle'
        case 'info':
          return 'fas fa-info-circle'
        default:
          return 'fas fa-chevron-circle-right'
      }
    }
  },
  watch: {
    message: function (newValue) {
      if (this.transient) {
        this.setTimeoutFunction()
      }
      if (newValue.length > 0) {
        this.showMessage = true
      }
      this.displayMessage = newValue
    },
    type: function (newValue) {
      this.messageType = newValue
    }
  },
  methods: {
    setTimeoutFunction: function () {
      this.showMessage = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.close()
      }, this.time)
    },
    close: function () {
      this.showMessage = false
      this.displayMessage = ''
      this.$emit('update:message', this.displayMessage)
    }
  }
}
</script>

<style lang="scss">

</style>
