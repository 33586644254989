const ClientFormBuilder = () => import('@/views/ClientOnboardingTool/ClientFormBuilder/ClientFormBuilder')
const ClientManagementGroups = () => import('@/views/ClientOnboardingTool/ClientManagementGroups/ClientManagementGroups')
const OnboardingProgressBoard = () => import('@/views/ClientOnboardingTool/OnboardingProgressBoard/OnboardingProgressBoard')
const OnboardingClientForm = () => import('@/views/ClientOnboardingTool/OnboardingForm/OnboardingClientForm')

const clientOnboardingRoutes = [
  {
    path: '/client-form-builder',
    name: 'client-form-builder',
    component: ClientFormBuilder,
    meta: {
      requiresAuth: true,
      module: 'client-onboarding-tool'
    }
  },
  {
    path: '/client-management-groups',
    name: 'client-management-groups',
    component: ClientManagementGroups,
    meta: {
      requiresAuth: true,
      module: 'client-onboarding-tool'
    }
  },
  {
    path: '/onboarding',
    name: 'onboarding-progress-board',
    component: OnboardingProgressBoard,
    meta: {
      requiresAuth: true,
      module: 'client-onboarding-tool'
    }
  },
  {
    path: '/onboarding/:id/',
    name: 'onboarding-client-forms',
    component: OnboardingClientForm,
    meta: {
      requiresAuth: true,
      module: 'client-onboarding-tool'
    }
  }
]

export default clientOnboardingRoutes
