<template>
  <div>
    <div class="d-flex align-items-center justify-content-center vh-100">
      <h1>Welcome, {{firstName(user)}}</h1>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    user: function () {
      return this.currentUser
    }
  },
  methods: {}
}
</script>
