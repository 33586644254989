const NapcPageTemplates = () => import('../views/NonAutomatedContent/NapcPageTemplates')
const NapcPageManagement = () => import('../views/NonAutomatedContent/NapcPageManagement')
const NapcPageContent = () => import('../views/NonAutomatedContent/NapcPageContent')
const ClientContents = () => import('../views/ClientContents/ClientContents')
const ClientContentsVisualisation = () => import('../views/ClientContents/ClientContentsVisualisation')

const nonAutomatedPlannedContentRoutes = [
  {
    path: '/napc-page-templates',
    name: 'napc-page-templates',
    component: NapcPageTemplates,
    meta: {
      requiresAuth: true,
      module: 'napc'
    },
  },
  {
    path: '/napc-page-management',
    name: 'napc-page-management',
    component: NapcPageManagement,
    meta: {
      requiresAuth: true,
      module: 'napc'
    },
  },
  {
    path: '/napc-page-content/:client_id/content/:id',
    name: 'napc-page-content',
    component: NapcPageContent,
    meta: {
      requiresAuth: true,
      module: 'napc'
    },
  },
  {
    path: '/client-contents/all',
    name: 'client-contents',
    component: ClientContents,
    meta: {
      requiresAuth: true,
      module: 'contents'
    },
  },
  {
    path: '/client-contents/visualisation',
    name: 'client-contents-visualisation',
    component: ClientContentsVisualisation,
    meta: {
      requiresAuth: true,
      module: 'contents'
    },
  },
]

export default nonAutomatedPlannedContentRoutes
