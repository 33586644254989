import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalMixin',
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'authStatus',
      'currentUser',
      'userRoles',
      'userPerms'
    ])
  },
  methods: {
    dateFormat (date, format) {
      if (format.toLowerCase() === 'datetime') {
        format = 'YYYY-MM-DDTHH:mm:ss'
      }
      return moment(date).format(format)
    },
    created (obj) {
      return obj
        ? this.this.dateFormat(obj.created_at, 'MMM D, YYYY h:mm A')
        : ''
    },
    updated (obj) {
      return obj ? this.dateFormat(obj.updated_at, 'MMM D, YYYY h:mm A') : ''
    },
    deleted (obj) {
      return obj &&
        obj.hasOwnProperty('deleted_at') &&
        !_.isNull(obj.deleted_at)
        ? this.dateFormat(obj.deleted_at, 'MMM D, YYYY h:mm A')
        : ''
    },
    terminated (obj) {
      return obj &&
        obj.hasOwnProperty('date_terminated') &&
        !_.isNull(obj.date_terminated)
        ? this.dateFormat(obj.date_terminated, 'MMM D, YYYY h:mm A')
        : ''
    },
    listingDate (date) {
      return this.dateFormat(date, 'MMM D, YYYY')
    },
    isDeleted (obj) {
      return this.deleted(obj) !== ''
    },
    dbDateTime (date) {
      return !_.isNull(date) ? this.dateFormat(date, 'datetime') : ''
    },
    firstName (userObj) {
      return userObj && userObj.hasOwnProperty('first_name')
        ? userObj.first_name
        : userObj && userObj.hasOwnProperty('name')
          ? userObj.name
          : ''
    },
    fullName (userObj) {
      return userObj &&
        userObj.hasOwnProperty('first_name') &&
        userObj.hasOwnProperty('last_name')
        ? userObj.first_name + ' ' + userObj.last_name
        : userObj && userObj.hasOwnProperty('name')
          ? userObj.name
          : ''
    },
    lastNameFirst (userObj) {
      return userObj &&
        userObj.hasOwnProperty('first_name') &&
        userObj.hasOwnProperty('last_name')
        ? userObj.last_name + ', ' + userObj.first_name
        : userObj && userObj.hasOwnProperty('name')
          ? userObj.name
          : ''
    },
    departmentName (userObj) {
      return userObj &&
        userObj.hasOwnProperty('department') &&
        userObj.department.hasOwnProperty('name')
        ? userObj.department.name
        : ''
    },
    positionName (userObj) {
      return userObj &&
        userObj.hasOwnProperty('position') &&
        userObj.position.hasOwnProperty('name')
        ? userObj.position.name
        : ''
    },
    truncate (value, length) {
      if (length <= 0) {
        length = 60
      }
      if (value.length > length) {
        value = value.substring(0, length - 3) + '...'
      }
      return value
    },
    capitalize (value) {
      return _.capitalize(value)
    },
    baseUrl (path) {
      const thePath = path || ''
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '') +
        thePath
      )
    },
    basePath (path) {
      const thePath = path || ''
      return '/v2' + thePath
    },
    imgUrl (image) {
      return image ? this.baseUrl('/images/' + image) : ''
    },
    validationErrorMsg (error) {
      if (error.response.status === 422 && error.response.data.message) {
        let message = error.response.data.message
        if (error.response.data.errors) {
          message +=
            ' ' +
            _.map(error.response.data.errors, (v) => {
              return Array.isArray(v) ? v.join(' ') : v
            }).join(' ')
        }
        return message
      }
      return false
    },
    hasRole (roleSlug) {
      return (
        this.userRoles.includes(roleSlug) ||
        this.userRoles.includes('super-admin')
      )
    },
    hasPerm (permSlug) {
      return (
        this.userPerms.includes(permSlug) ||
        this.userRoles.includes('super-admin')
      )
    },
    makeKey (length) {
      const result = []
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        )
      }
      return result.join('')
    },
    isFunction (v) {
      return typeof v === 'function'
    },
    getFormattedDate (datestring, pretty) {
      var date = new Date(datestring)
      var year = date.getFullYear()
      var month = (1 + date.getMonth()).toString()
      var day = date.getDate().toString()

      month = month.length > 1 ? month : '0' + month
      day = day.length > 1 ? day : '0' + day

      if (pretty) {
        return month + '/' + day + '/' + year
      } else {
        return year + '-' + month + '-' + day
      }
    },
    getObjectArrayDiff (objArr1, objArr2) {
      for (let i = objArr2.length - 1; i >= 0; i--) {
        if (objArr1?.[i] === undefined) {
          return i
        }

        if (!_.isEqual(objArr1[i], objArr2[i])) {
          return i
        }
      }
      return null
    },
    getInstanceEnv () {
      let env = 'local'
      switch (location.hostname) {
        case 'dalmatian-dev.reunionmarketing.com':
          env = 'dev'
          break
        case 'dalmatian-uat.reunionmarketing.com':
          env = 'uat'
          break
        case 'dalmatian.reunionmarketing.com':
          env = 'prod'
          break
      }
      return env
    },
    replaceBulk (str, findArray, replaceArray) {
      var i
      var regex = []
      var map = {}
      for (i = 0; i < findArray.length; i++) {
        regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'))
        map[findArray[i]] = replaceArray[i]
      }
      regex = regex.join('|')
      str = str.replace(new RegExp(regex, 'g'), function (matched) {
        return map[matched]
      })
      return str
    },
    getDayList () {
      return [
        {
          text: 'SUNDAY',
          value: 'SUNDAY'
        },
        {
          text: 'MONDAY',
          value: 'MONDAY'
        },
        {
          text: 'TUESDAY',
          value: 'TUESDAY'
        },
        {
          text: 'WEDNESDAY',
          value: 'WEDNESDAY'
        },
        {
          text: 'THURSDAY',
          value: 'THURSDAY'
        },
        {
          text: 'FRIDAY',
          value: 'FRIDAY'
        },
        {
          text: 'SATURDAY',
          value: 'SATURDAY'
        }
      ]
    },
    getMsSmClasses () {
      return {
        container: 'multiselect form-control form-control-sm',
        search: 'multiselect-search form-control form-control-sm',
        singleLabel: 'multiselect-single-label form-control form-control-sm ps-4',
        caret: 'd-none',
        option: 'multiselect-option form-control-sm',
        optionPointed: 'is-pointed form-control-sm',
        optionSelected: 'is-selected form-control-sm',
        optionDisabled: 'is-disabled form-control-sm',
        optionSelectedPointed: 'is-selected is-pointed form-control-sm',
        optionSelectedDisabled: 'is-selected is-disabled form-control-sm'
      }
    },
    getMonthList () {
      return [
        {
          value: 1,
          text: 'January'
        },
        {
          value: 2,
          text: 'February'
        },
        {
          value: 3,
          text: 'March'
        },
        {
          value: 4,
          text: 'April'
        },
        {
          value: 5,
          text: 'May'
        },
        {
          value: 6,
          text: 'June'
        },
        {
          value: 7,
          text: 'July'
        },
        {
          value: 8,
          text: 'August'
        },
        {
          value: 9,
          text: 'September'
        },
        {
          value: 10,
          text: 'October'
        },
        {
          value: 11,
          text: 'November'
        },
        {
          value: 12,
          text: 'December'
        }
      ]
    },
    getYearsList (startYear) {
      const currentYear = new Date().getFullYear()
      const years = []
      startYear = startYear || 1980
      while (startYear <= currentYear) {
        startYear++
        years.push({ value: startYear, text: startYear })
      }
      return years
    }
  }
}
