<template>
  <div class="container fluid fill-height">
    <div class="align-center justify-center">
      <div class="text-md-center">
        <h1>404</h1>
        <h2 class="my-3 headline">Sorry, page not found</h2>
        <div>
          <button color="primary" @click="goBack">Take me back!</button>
          <button color="primary" @click="goHome">Go Home</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    goHome () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss">

</style>
