<template>
  <div class="navbar-dark sidebar-content m-0 p-0" style="min-height: 60px">
    <div id="panelNavDropdown" class="w-100">
      <ul class="list-unstyled ps-0" v-if="sidebarMenu && sidebarMenu !== {}">
        <sidebar-item v-for="menuItem in sidebarMenu[menuId]" :menuItem="menuItem" :key="menuItem.id" />
      </ul>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem.vue'

export default {
  name: 'DynamicSidebar',
  props: {
    menuId: [Number, String]
  },
  components: {
    SidebarItem
  },
  watch: {
    menuId () {
      this.getSidebar()
    }
  },
  computed: {
    ...mapGetters([
      'sidebarMenu'
    ])
  },
  methods: {
    getSidebar () {
      if (!this.sidebarMenu?.menuId) this.$store.dispatch('setSidebarMenu', this.menuId)
    }
  },
  mounted () {
    if (this.menuId) {
      this.getSidebar()
    }
  }
}
</script>
