<template>
  <div class="d-flex" id="wrapper">

    <!-- Page Content -->
    <div id="page-content-wrapper">

      <div class="navbar-wrapper navbar-expand-md navbar-dark bg-dark d-flex">
        <div class="col d-flex align-items-start">
            <router-link :to="{ name: 'home' }" class="nav-link">
                <img :src="baseUrl('/images/reunion-marketing-logo.svg')"
                     style="max-width: 100%; width: 185px"
                     class="float-left"
                     alt="Reunion Marketing">
            </router-link>
        </div>
        <div class="col-auto d-flex align-items-end">
          <ul class="navbar-nav ms-auto">
            <li v-if="!isLoggedIn && !isLoginPage" class="nav-item">
              <router-link :to="{ name: 'auth.login' }" class="nav-link">
                <i class="fas fa-sign-in-alt"></i>&nbsp;Login
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="container-fluid">

        <inline-message :message="appSuccessMsg" type="success" :dismissible="true" :transient="true"/>
        <inline-message :message="appErrorMsg" type="danger" :dismissible="true" :transient="true"/>
        <inline-message :message="appWarningMsg" type="warning" :dismissible="true" :transient="true"/>
        <inline-message :message="appInfoMsg" type="info" :dismissible="true" :transient="true"/>

        <!-- force shared component to reload data when changing path -->
        <router-view :key="$route.fullPath"></router-view>
      </div>

    </div>
    <!-- /#page-content-wrapper -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AuthLayout',
  data () {
    return {
      isLoginPage: false
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'appError',
      'appSuccess',
      'appWarning',
      'appInfo'
    ]),
    appErrorMsg: function () {
      return this.appError
    },
    appSuccessMsg: function () {
      return this.appSuccess
    },
    appWarningMsg: function () {
      return this.appWarning
    },
    appInfoMsg: function () {
      return this.appInfo
    }
  },
  methods: {},
  mounted () {
    this.isLoginPage = this.$route.name === 'auth.login'
  },
  watch: {
    $route () {
      this.isLoginPage = this.$route.name === 'auth.login'
    }
  }
}
</script>

<style lang="scss">

</style>
