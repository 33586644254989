<template>
<li>
  <template v-if="menuItem.list.length > 0">
    <div
      class="d-flex sidebar-item"
      :class="isActive(menuItem, 'menuItem') ? 'active' : ''"
    >
      <a class="nav-link flex-grow-1 text-white" :href="menuItem.path" >
        {{ menuItem.name }}
      </a>
      <button
        class="btn btn-toggle align-items-center accordion-button p-2"
        :class="menuItem.parent_id ? 'collapsed' : null"
        data-bs-toggle="collapse"
        :data-bs-target="`#${menuItem.slug}-collapse`"
        aria-expanded="true"
      >
      </button>
    </div>
    <div class="collapse"
      :id="`${menuItem.slug}-collapse`"
      :class="!menuItem.parent_id || isMenuCollapsed? 'show' : null"
    >
      <ul class="btn-toggle-nav list-unstyled fw-normal pb-1">
        <li v-for="subItem in menuItem.list" :key="subItem.id">
          <sidebar-item :menuItem="subItem" v-if="subItem.list.length > 0" :href="subItem.path" />
          <a v-else
            class="nav-link flex-grow-1 text-white"
            :href="subItem.path"
            @click.prevent="() => onMenuClick(subItem)"
            :class="isActive(subItem, 'subItem') ? 'active' : ''"
          >{{ subItem.name }}</a>
        </li>
      </ul>
    </div>
  </template>
  <template v-else>
    <a
      class="nav-link sidebar-item flex-grow-1 text-white"
      :href="menuItem.path"
      :class="isActive(menuItem, 'menuItem') ? 'active' : ''"
    >
        {{ menuItem.name }}
      </a>
  </template>
</li>
</template>

<script>

import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'SidebarItem',
  props: {
    menuItem: Object
  },
  data () {
    return {
      isMenuCollapsed: false
    }
  },
  methods: {
    onMenuClick (item) {
      if (item.link_target === '_self') {
        this.$router.push(item.path)
      } else {
        window.open(item.path, item.link_target)
      }
    },
    shouldShow (module) {
      return module === this.module ? 'show' : ''
    },
    ariaExpanded (module) {
      return module === this.module ? 'true' : 'false'
    },
    isActive (menuItem, location) {
      if (this.$route.path === menuItem.path) {
        this.isMenuCollapsed = true
        return true
      }

      const routeParts = this.$route.path.split('/')
      const menuParts = menuItem.path.split('/')

      const diffPathParts = _.xor(routeParts, menuParts)
      for (let i = 0; i < diffPathParts.length; i++) {
        if (diffPathParts[i] && diffPathParts[i] !== '' && isNaN(diffPathParts[i])) {
          return false
        }
      }
      return true
    }
  },
  computed: {
    ...mapGetters([
      'activeSidebar'
    ])
  }
}
</script>
