const Providers = () => import('@/views/ServicePackages/Providers')
const Services = () => import('@/views/ServicePackages/Services')
const Offerings = () => import('@/views/ServicePackages/Offerings')
const Rules = () => import('@/views/ServicePackages/Rules')
const ServicePackages = () => import('@/views/ServicePackages/ServicePackages')
const ServicePackage = () => import('@/views/ServicePackages/ServicePackage')

const servicePackageRoutes = [
  {
    path: '/service-packages/providers',
    name: 'service-packages.providers',
    component: Providers,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    }
  },
  {
    path: '/service-packages/services',
    name: 'service-packages.services',
    component: Services,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    }
  },
  {
    path: '/service-packages/offerings',
    name: 'service-packages.offerings',
    component: Offerings,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    }
  },
  {
    path: '/service-packages/rules',
    name: 'service-packages.rules',
    component: Rules,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    }
  },
  {
    path: '/service-packages/rules/services/:id/',
    name: 'service-packages.rules.filtered.services',
    component: Rules,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    }
  },
  {
    path: '/service-packages/rules/offerings/:id',
    name: 'service-packages.rules.filtered.offerings',
    component: Rules,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    }
  },
  {
    path: '/service-packages',
    name: 'service-packages',
    component: ServicePackages,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    },
    redirect: '/service-packages/list'
  },
  {
    path: '/service-packages/list',
    name: 'service-packages.list',
    component: ServicePackages,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    }
  },
  {
    path: '/service-packages/new',
    name: 'service-packages.new',
    component: ServicePackage,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    }
  },
  {
    path: '/service-packages/:id',
    name: 'service-packages.edit',
    component: ServicePackage,
    meta: {
      requiresPerm: 'service-packages-view',
      requiresAuth: true,
      module: 'service-packages'
    }
  }
]

export default servicePackageRoutes
