<template>
  <button class="button" @click.prevent="buttonClicked">
    <span v-if="showConfirm">Confirm action?</span>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  name: 'ConfirmButton',
  data () {
    return {
      timeout: null,
      showConfirm: false
    }
  },
  methods: {
    buttonClicked () {
      if (this.showConfirm) {
        clearTimeout(this.timeout)
        this.showConfirm = false
        this.$emit('confirmed')
      } else {
        this.showConfirm = true
        this.timeout = setTimeout(() => {
          this.showConfirm = false
        }, 2000)
      }
    }
  }
}
</script>
