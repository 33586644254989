const GaDdClients = () => import('@/views/GaDdTool/GoogleAdClients')
const AdCopyTool = () => import('@/views/GaDdTool/AdCopyTool')
const GoogleAdsTemplates = () => import('@/views/GaDdTool/GoogleAdsTemplates')
const CampaignTemplateDetail = () => import('@/views/GaDdTool/components/CampaignTemplateDetail')

const gaDdToolRoutes = [
  {
    path: '/ga-dd-clients',
    name: 'gadd.clients',
    component: GaDdClients,
    meta: {
      requiresAuth: true,
      module: 'gadd'
    }
  },
  {
    path: '/ga-dd-clients/:id',
    name: 'gadd.clients.adcopy',
    component: AdCopyTool,
    meta: {
      requiresAuth: true,
      module: 'gadd'
    }
  },
  {
    path: '/google-ads/templates',
    name: 'gadd.templates',
    component: GoogleAdsTemplates,
    meta: {
      requiresAuth: true,
      module: 'gadd'
    }
  },
  {
    path: '/google-ads/templates/:template_id/campaigns/:campaign_template_id',
    name: 'gadd.templates.campaigns',
    component: CampaignTemplateDetail,
    meta: {
      requiresAuth: true,
      module: 'gadd'
    }
  }
]

export default gaDdToolRoutes
