const ClientInformationDashboard = () => import('@/views/ClientInformation/Dashboard')
const ClientInfo = () => import('@/views/ClientInformation/Info')
const ClientProfile = () => import('@/views/ClientInformation/ClientProfile/ClientProfile')
const SeoInfo = () => import('@/views/ClientInformation/Seo')
const PaidInfo = () => import('@/views/ClientInformation/Paid')
const SocialInfo = () => import('@/views/ClientInformation/Social')
const VideoInfo = () => import('@/views/ClientInformation/Video')
const SpecialsInfo = () => import('@/views/ClientInformation/Specials')
const OttInfo = () => import('@/views/ClientInformation/Ott')

const clientInfoCollectionRoutes = [
  {
    path: '/clients/dashboard',
    name: 'client-information.dashboard',
    component: ClientInformationDashboard,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/:id/dashboard',
    name: 'client-information.client.dashboard',
    component: ClientInformationDashboard,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/new',
    name: 'clients.new',
    component: ClientProfile,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/:id',
    name: 'clients.edit',
    component: ClientProfile,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/new/prospect/:prospect',
    name: 'clients.prospect.new',
    component: ClientProfile,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/dashboard/info',
    name: 'client-information.info',
    component: ClientInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/dashboard/seo',
    name: 'client-information.seo',
    component: SeoInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/dashboard/sem',
    name: 'client-information.paid',
    component: PaidInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/dashboard/social',
    name: 'client-information.social',
    component: SocialInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/dashboard/video',
    name: 'client-information.video',
    component: VideoInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/dashboard/specials',
    name: 'client-information.specials',
    component: SpecialsInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/dashboard/ott',
    name: 'client-information.ott',
    component: OttInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/:id/dashboard/info',
    name: 'client-information.client.info',
    component: ClientInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/:id/dashboard/seo',
    name: 'client-information.client.seo',
    component: SeoInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/:id/dashboard/sem',
    name: 'client-information.client.paid',
    component: PaidInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/:id/dashboard/social',
    name: 'client-information.client.social',
    component: SocialInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/:id/dashboard/video',
    name: 'client-information.client.video',
    component: VideoInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/:id/dashboard/specials',
    name: 'client-information.client.specials',
    component: SpecialsInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  },
  {
    path: '/clients/:id/dashboard/ott',
    name: 'client-information.client.ott',
    component: OttInfo,
    meta: {
      requiresAuth: true,
      module: 'client-information'
    }
  }
]

export default clientInfoCollectionRoutes
