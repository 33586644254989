import axios from 'axios'

let baseUrl = `${location.protocol}//127.0.0.1`
switch (location.hostname) {
  case 'dalmatian.reunionmarketing.com':
    baseUrl = `${location.protocol}//keylift-api.reunionmarketing.com`
    break
}

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
  },
  withCredentials: true
})

export default instance
