<template>
    <!-- force shared component to reload data when changing path -->
    <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {}
  },
  methods: {},
  created () {
    this.$api.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
        }
        throw err
      })
    })

    // const dalmatianMenuId = 1
    // this.$api.get(`/dynamic-menus/menus/${dalmatianMenuId}/items?show_deleted=0`)
    //   .then(response => {
    //     console.log(response.data)
    //   }, (error) => {
    //     this.sendError(error)
    //   })
  }
}
</script>
<style lang="scss">
  @import "assets/sass/app";
</style>
