<template>
  <div :class="parentClass">
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-dialog-scrollable" :class="modalStyle" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    <slot name="title"></slot>
                  </h5>
                  <button type="button" class="btn-close" aria-label="Close" @click.prevent="close"></button>
                </div>
                <div class="modal-body">
                  <slot name="body"></slot>
                </div>
                <div class="modal-footer" v-if="showFooter">
                  <div class="mx-auto">
                    <button type="button" :class="closeButtonStyle" class="mx-1" @click.prevent="close">
                      <span v-html="closeButtonIcon"></span>
                      {{ closeButtonText }}
                    </button>
                    <button v-if="hasAction" type="button" :class="actionButtonStyle" class="mx-1" @click.prevent="action" :disabled="loading">
                      <span v-if="!loading" v-html="actionButtonIcon"></span>
                      <i v-else class="fas fa-circle-notch fa-spin"></i>
                      {{ actionButtonText }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <button v-if="openButtonTooltip" id="openModalButton" :class="openButtonStyle" :disabled="openButtonDisabled"
            @click.prevent="open" v-tooltip="openButtonTooltip">
      <loading-icon :loading="buttonLoading">
        <span v-html="openButtonIcon"></span>
      </loading-icon>
      <span :class="openButtonTextStyle">{{ openButtonText }}</span>
    </button>
    <button v-else id="openModalButton" :class="openButtonStyle" :disabled="openButtonDisabled" @click.prevent="open">
      <slot name="openModal">
        <loading-icon :loading="buttonLoading">
          <span v-html="openButtonIcon"></span>
        </loading-icon>
        {{ openButtonText }}
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  emits: [
    'action',
    'open',
    'close'
  ],
  props: {
    modalStyle: {
      type: String,
      default: ''
    },
    openModalLabel: {
      type: String,
      default: ''
    },
    openButtonStyle: {
      type: String,
      default: 'btn btn-success'
    },
    openButtonIconStyle: {
      type: String,
      default: ''
    },
    openButtonText: {
      type: String,
      default: 'Open'
    },
    openButtonTooltip: {
      type: String,
      default: ''
    },
    openButtonDisabled: {
      type: Boolean,
      default: false
    },
    openButtonTextStyle: {
      type: String,
      default: ''
    },
    actionButtonStyle: {
      type: String,
      default: 'btn btn-primary'
    },
    actionButtonIconStyle: {
      type: String,
      default: ''
    },
    actionButtonText: {
      type: String,
      default: 'Submit'
    },
    closeButtonStyle: {
      type: String,
      default: 'btn btn-danger'
    },
    closeButtonIconStyle: {
      type: String,
      default: ''
    },
    closeButtonText: {
      type: String,
      default: 'Close'
    },
    buttonLoading: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasAction: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showModal: false,
      parentClass: ''
    }
  },
  computed: {
    openButtonIcon: function () {
      return this.openButtonIconStyle.length > 0 ? '<i class="' + this.openButtonIconStyle + '"></i>&nbsp;' : ''
    },
    closeButtonIcon: function () {
      return this.closeButtonIconStyle.length > 0 ? '<i class="' + this.closeButtonIconStyle + '"></i>&nbsp;' : ''
    },
    actionButtonIcon: function () {
      return this.actionButtonIconStyle.length > 0 ? '<i class="' + this.actionButtonIconStyle + '"></i>&nbsp;' : ''
    },
    loading: {
      get () {
        return this.isLoading || false
      },
      set (newValue) {
        return newValue
      }
    }
  },
  watch: {
    closeModal: {
      // watch for props to load
      immediate: true,
      handler (newValue) {
        if (newValue === true) {
          this.close()
        }
      }
    },
    isLoading: {
      // watch for props to load
      immediate: true,
      handler (newValue) {
        this.loading = newValue === true
      }
    }
  },
  methods: {
    action () {
      this.$emit('action')
    },
    open () {
      this.showModal = true
      this.$emit('open')
      this.parentClass = 'modal-open'
    },
    close () {
      this.showModal = false
      this.$emit('close')
      this.parentClass = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;

  .modal-content {
    position: static;
    .modal-body {
      max-height: 80vh;
      position: static;
    }
  }
}

.modal-dialog {
    .modal-content {

        .modal-body {
            min-height: 10vw;
            padding-bottom: 160px;
        }
    }
}

#openModalButton {
   text-align: left;
}
</style>
