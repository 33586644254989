<template>
  <button class="btn" :disabled="loading||disabled">
    <slot name="default" v-if="!loading"></slot>
    <i v-else class="fas fa-circle-notch fa-spin"></i>
    <slot name="text"></slot>
  </button>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
