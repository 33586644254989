<template>
    <span>
        <slot v-if="!loading"></slot>
        <i v-else class="fas fa-circle-notch fa-spin" style="color: lightgray"></i>
    </span>
</template>

<script>
export default {
  name: 'LoadingIcon',
  inheritAttrs: true,
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
