const KeyliftClientOverview  = () => import('../views/KeyliftReporting/KeyliftClientOverview.vue')
const KeyliftReportingDashboard = () => import('../views/KeyliftReporting/KeyliftReportingDashboard.vue')

const keyliftReportingRoutes = [
  {
    path: '/keylift-reporting-dashboard',
    name: 'keylift-reporting-dashboard',
    component: KeyliftReportingDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/keylift-client-overview',
    name: 'keylift-client-overview',
    component: KeyliftClientOverview,
    meta: {
      requiresAuth: true
    }
  }
]

export default keyliftReportingRoutes
