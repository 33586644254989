<template>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card m-5">
        <div class="card-header text-center"><h3>Reset Password</h3></div>
        <div class="card-body">
          <div>
            <inline-message :message="displayMessage" :type="messageType"/>
            <form autocomplete="off" @submit.prevent="submit">
              <div class="mb-3" :class="{ 'hasError': v$.form.email.$errors.length }">
                <label for="email">E-mail</label>
                <input type="email" id="email" class="form-control" placeholder="user@example.com"
                       v-model="form.email" autocomplete="email">
                <div class="error" v-if="v$.form.email.required.$invalid  && v$.form.email.$dirty">Email is required</div>
                <div class="error" v-if="v$.form.email.email.$invalid && v$.form.email.$dirty">
                  Email is not a properly formatted email address
                </div>
              </div>
              <div class="mb-3 d-flex mb-0">
                <div class="mx-auto">
                  <loading-button type="submit" class="btn btn-primary" :loading="isLoading">
                    <template v-slot:default><i class="far fa-envelope"></i></template>
                    <template v-slot:text>&nbsp;Send Password Reset Link</template>
                  </loading-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'

export default {
  name: 'ResetEmail',
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      form: {
        email: null
      },
      isLoading: false,
      displayMessage: '',
      messageType: ''
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    submit () {
      this.resetMessage()
      this.v$.form.$touch()
      this.invalid = this.v$.form.$invalid
      if (this.invalid === false) {
        this.reset()
      } else {
        this.showMessage('danger', 'The form has errors, please fix them.')
      }
    },
    reset () {
      this.isLoading = true
      this.resetMessage()
      this.$api.post('/forgot',
        {
          email: this.form.email
        })
        .then(response => {
          this.isLoading = false
          this.showMessage('success', response.data.success)
        }, (error) => {
          console.log(error)
          this.isLoading = false
          this.showMessage('danger', 'There was an error, unable to locate that email address.')
        })
    },
    showMessage (type, msg) {
      this.displayMessage = msg
      this.messageType = type
    },
    resetMessage () {
      this.displayMessage = this.messageType = ''
    }
  }
}
</script>
