<template>
  <div class="pretty p-default p-round">
    <input type="radio" v-bind="$attrs" :checked="isChecked"
           @input="$emit('update:modelValue', $event.target.value)"/>
    <div class="state p-primary-o">
      <label>
        <slot></slot>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrettyRadio',
  inheritAttrs: false,
  props: {
    modelValue: {
      default: '',
      required: true
    }
  },
  computed: {
    isChecked () {
      return this.modelValue === this.$attrs.value
    }
  }
}
</script>
