const GbpAccounts = () => import('../views/GbpManagement/GbpAccounts')
const GbpGlobalProspectAccounts = () => import('../views/GbpManagement/GbpGlobalProspectAccounts')
const GbpAccount = () => import('../views/GbpManagement/GbpAccount')
const GbpGlobalProspectAccount = () => import('../views/GbpManagement/GbpGlobalProspectAccount')
const GbpRawAgencyData = () => import('../views/GbpManagement/agencyReporting/RawData')
const GbpLocations = () => import('../views/GbpManagement/GbpLocations')
const GbpAgencyDrillDown = () => import('../views/GbpManagement/agencyReporting/AgencyDrillDown')
const GbpAgencyHvacDrillDown = () => import('../views/GbpManagement/agencyReporting/AgencyHvacDrillDown')
const GbpLocationReporting = () => import('../views/GbpManagement/LocationReporting')
const GbpClientSettings = () => import('../views/GbpManagement/GbpClientSettings')
const GbpAgencyProspectDrillDown = () => import('../views/GbpManagement/agencyReporting/AgencyProspectDrillDown')
const LocationReportingHistorical = () => import('../views/GbpManagement/LocationReportingHistorical')
const GbpProspectLocationReporting = () => import('../views/GbpManagement/ProspectLocationReporting')
const GbpProspectLocations = () => import('../views/GbpManagement/GbpProspectLocations')
const AgencyReportingSettings = () => import('../views/GbpManagement/agencyReporting/Settings')

const GbpRoutes = [
  {
    path: '/gbp',
    name: 'gbp',
    component: GbpAccounts,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    },
    redirect: '/gbp/list'
  },
  {
    path: '/gbp/list',
    name: 'gbp.list',
    component: GbpAccounts,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/new',
    name: 'gbp.new',
    component: GbpAccount,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/:id',
    name: 'gbp.edit',
    component: GbpAccount,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/agency-reporting/raw',
    name: 'gbp.agency.raw',
    component: GbpRawAgencyData,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/agency-reporting',
    name: 'gbp.agency.drillDown',
    component: GbpAgencyDrillDown,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/locations',
    name: 'gbp.locations',
    component: GbpLocations,
    props: true,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: 'gbp/locations/:id/reporting',
    name: 'gbp.location.reporting',
    component: GbpLocationReporting,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    name: 'gbp.reporting.historical',
    path: 'gbp/locations/:id/reporting/historical',
    component: LocationReportingHistorical,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/agency-hvac-reporting',
    name: 'gbp.agency.hvac.drillDown',
    component: GbpAgencyHvacDrillDown,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/clients',
    name: 'gbp.settings',
    component: GbpClientSettings,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/clients/:id/settings',
    name: 'gbp.client.settings',
    component: GbpClientSettings,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/agency-prospect-reporting',
    name: 'gbp.agency.prospect.drillDown',
    component: GbpAgencyProspectDrillDown,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/prospect-locations',
    name: 'gbp.prospect.locations',
    component: GbpProspectLocations,
    props: true,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: 'gbp/prospect-locations/:id/reporting',
    name: 'gbp.prospect.location.reporting',
    component: GbpProspectLocationReporting,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    name: 'gbp.prospect.reporting.historical',
    path: 'gbp/prospect-locations/:id/reporting/historical',
    component: LocationReportingHistorical,
    meta: {
      requiresPerm: 'gbp-view',
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/global-prospect-account',
    name: 'gbp.global-prospect-account',
    component: GbpGlobalProspectAccounts,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    },
    redirect: '/gbp/list'
  },
  {
    path: '/gbp/global-prospect-account/list',
    name: 'gbp.global-prospect-account.list',
    component: GbpGlobalProspectAccounts,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/global-prospect-account/new',
    name: 'gbp.global-prospect-account.new',
    component: GbpGlobalProspectAccount,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/global-prospect-account/:id',
    name: 'gbp.global-prospect-account.edit',
    component: GbpGlobalProspectAccount,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  },
  {
    path: '/gbp/agency-reporting/settings',
    name: 'gbp.agency-reporting.settings',
    component: AgencyReportingSettings,
    meta: {
      requiresAuth: true,
      module: 'gbp-tool'
    }
  }
]

export default GbpRoutes
