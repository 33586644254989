const PartnersProfile = () => import('../views/Partners/PartnerProfile')
const Partners = () => import('../views/Partners/Partners')

const partnersRoutes = [
  {
    path: '/partners/new',
    name: 'partner.new',
    component: PartnersProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partners/:id',
    name: 'partner.edit',
    component: PartnersProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partners',
    name: 'partners.list',
    component: Partners,
    meta: {
      requiresAuth: true
    }
  }
]

export default partnersRoutes
