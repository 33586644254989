const Users = () => import('../views/UserManagement/Users')
const User = () => import('../views/UserManagement/User')
const UserProfile = () => import('../views/UserManagement/UserProfile')
const UserTokens = () => import('../views/UserManagement/UserTokens')
const Departments = () => import('../views/UserManagement/Departments')
const Positions = () => import('../views/UserManagement/Positions')
const Roles = () => import('../views/UserManagement/Roles')
const Role = () => import('../views/UserManagement/Role')
const Permissions = () => import('../views/UserManagement/Permissions')
const Permission = () => import('../views/UserManagement/Permission')

const userManagementRoutes = [
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    },
    redirect: '/users/list'
  },
  {
    path: '/users/list',
    name: 'users.list',
    component: Users,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/users/profile',
    name: 'users.profile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      module: 'profile'
    }
  },
  {
    path: '/users/new',
    name: 'users.new',
    component: User,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/users/departments',
    name: 'users.departments',
    component: Departments,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/users/positions',
    name: 'users.positions',
    component: Positions,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    component: User,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/roles',
    name: 'roles',
    component: Roles,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    },
    redirect: '/roles/list'
  },
  {
    path: '/roles/list',
    name: 'roles.list',
    component: Roles,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/roles/new',
    name: 'roles.new',
    component: Role,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/roles/:id',
    name: 'roles.edit',
    component: Role,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: Permissions,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    },
    redirect: '/permissions/list'
  },
  {
    path: '/permissions/list',
    name: 'permissions.list',
    component: Permissions,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/permissions/new',
    name: 'permissions.new',
    component: Permission,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/permissions/:id',
    name: 'permissions.edit',
    component: Permission,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/partner-users',
    name: 'partner-users',
    component: Users,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    },
    redirect: '/partner-users/list'
  },
  {
    path: '/partner-users/list',
    name: 'partner-users.list',
    component: Users,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/partner-users/new',
    name: 'partner-users.new',
    component: User,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/partner-users/profile',
    name: 'partner-users.profile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      module: 'profile'
    }
  },
  {
    path: '/partner-users/:id',
    name: 'partner-users.edit',
    component: User,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/partner-users/tokens',
    name: 'partner-users.tokens',
    component: UserTokens,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/client-users',
    name: 'client-users',
    component: Users,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    },
    redirect: '/client-users/list'
  },
  {
    path: '/client-users/list',
    name: 'client-users.list',
    component: Users,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/client-users/new',
    name: 'client-users.new',
    component: User,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/client-users/profile',
    name: 'client-users.profile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      module: 'profile'
    }
  },
  {
    path: '/client-users/:id',
    name: 'client-users.edit',
    component: User,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  },
  {
    path: '/client-users/tokens',
    name: 'client-users.tokens',
    component: UserTokens,
    meta: {
      requiresAuth: true,
      module: 'user-management'
    }
  }
]

export default userManagementRoutes
