const userData = {
  state: {
    userPageData: {}
  },
  mutations: {
    save_user_page_data (state, payload) {
      if (!(payload.page in state.userPageData)) {
        state.userPageData[payload.page] = {}
      }
      state.userPageData[payload.page] = payload.data
    }
  },
  actions: {
    saveUserPageData ({ commit }, payload) {
      return new Promise((resolve) => {
        commit('save_user_page_data', {
          page: payload.page,
          data: payload.data
        })
        resolve()
      })
    },
    deleteUserPageData ({ commit }, payload) {
      return new Promise((resolve) => {
        commit('save_user_page_data', {
          page: payload.page,
          data: null
        })
        resolve()
      })
    }
  },
  getters: {
    userPageData: (state) => (page) => {
      return (state.userPageData && state.userPageData.hasOwnProperty(page)) ? state.userPageData[page] : null
    }
  }
}

export default userData
