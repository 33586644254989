<template>
  <div :class="outerClasses + ' ' + classes">
    <input type="checkbox" v-bind="$attrs" :checked="isChecked" @change="updateInput"/>
    <div :class="'state p-'+color">
      <!-- svg path -->
      <svg v-if="!isSwitch" class="svg svg-icon" viewBox="0 0 20 20">
        <path
          d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
          style="stroke: white;fill:white;"></path>
      </svg>
      <label>
        <slot></slot>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrettyCheckbox',
  inheritAttrs: false,
  emits: [
    'onChange'
  ],
  props: {
    modelValue: {
      default: '',
      required: true
    },
    classes: {
      type: String,
      default: ''
    },
    color: { default: 'primary' },
    isSwitch: { default: false },
    trueValue: { default: true },
    falseValue: { default: false }
  },
  computed: {
    isChecked () {
      if (this.modelValue !== undefined) {
        if (this.modelValue instanceof Array) {
          // fix for type, check for sting versions of integer id's
          return (this.modelValue.includes(this.$attrs.value) || this.modelValue.includes(String(this.$attrs.value)))
        }
        return this.modelValue === this.trueValue
      } else {
        return this.falseValue
      }
    },
    outerClasses () {
      return this.isSwitch ? 'pretty p-switch p-fill' : 'pretty p-svg p-curve'
    }
  },
  methods: {
    updateInput (event) {
      /** Warning! ** Do not use "event.target.value" ** it will return a string for an integer or bool */
      const isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.$attrs.value)
        } else {
          newValue.splice(newValue.indexOf(this.$attrs.value), 1)
        }
        this.$emit('update:modelValue', newValue)
        this.$emit('changed', event)
        this.$emit('onChange', newValue)
        this.$emit('onSelect', this.$attrs.value, isChecked)
      } else {
        this.$emit('update:modelValue', isChecked ? this.trueValue : this.falseValue)
        this.$emit('changed', event)
        this.$emit('onChange', isChecked ? this.trueValue : this.falseValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .pretty {
    white-space: inherit;
    width: 100%;
  }

  .pretty.p-svg .state {
    .svg.svg-icon {
      top: 0;
    }
    label {
      text-indent: 0;
      padding-left: 2rem;
    }
  }

  .pretty .state label:after,
  .pretty .state label:before {
    top: 0;
  }
</style>
