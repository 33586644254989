<template>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card m-5">
        <div class="card-header text-center"><h3>Register</h3></div>
        <div class="card-body">
          <div>
            <inline-message :message="displayMessage" :type="messageType"/>
            <form @submit.prevent="submit">
              <div class="mb-3">
                <label for="name">Name</label>
                <input type="text" id="name" class="form-control" placeholder="John Smith"
                       v-model="form.name">
                <div class="error" v-if="v$.form.name.required.$invalid  && v$.form.name.$dirty">Name is required</div>
              </div>
              <div class="mb-3">
                <label for="email">E-mail</label>
                <input type="email" id="email" class="form-control" placeholder="staff@dealership.com"
                       v-model="form.email">
                <div class="error" v-if="v$.form.email.required.$invalid  && v$.form.email.$dirty">Email is required</div>
                <div class="error" v-if="v$.form.email.email.$invalid && v$.form.email.$dirty">
                  Email is not a properly formatted email address
                </div>
                <div class="error" v-if="v$.form.email.isUnique.$invalid && v$.form.email.$dirty">
                  The Email "{{ form.email }}" is already registered.
                </div>
              </div>
              <div class="mb-3">
                <label for="password">Password</label>
                <input type="password" id="password" class="form-control" v-model="form.password">
                <div class="error" v-if="v$.form.password.strongPassword.$invalid  && v$.form.password.$dirty">
                  Passwords need to be at least 4 characters long.
                </div>
              </div>
              <div class="mb-3">
                <label for="confirm_password">Confirm Password</label>
                <input type="password" id="confirm_password" class="form-control"
                       v-model="form.confirm_password">
                <div class="error" v-if="v$.form.confirm_password.required.$invalid && v$.form.confirm_password.$dirty">
                  Password confirmation is required
                </div>
                <div class="error" v-if="v$.form.confirm_password.sameAsPassword.$invalid && v$.form.confirm_password.$dirty">
                  The passwords do not match.
                </div>
              </div>
              <div class="mb-3 d-flex mb-0">
                <div class="mx-auto">
                  <loading-button class="btn btn-primary" @submit.prevent="submit" :loading="isLoading">
                    <template v-slot:default><i class="fas fa-sign-in-alt"></i></template>
                    <template v-slot:text>&nbsp;Register</template>
                  </loading-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, required } from '@vuelidate/validators'
import axios from '../../plugins/axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Register',
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      form: {
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        token: null
      },
      isLoading: false,
      displayMessage: '',
      messageType: ''
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      email: {
        required,
        email,
        isUnique: helpers.withAsync(async (value) => {
          // standalone validator ideally should not assume a field is required
          if (value === '' || value === null) return true
          const response = await axios.get('/email-exists/' + value)
          return !response.data
        })
      },
      password: {
        required,
        strongPassword (password) {
          return (
            password !== null &&
            // /[a-z]/.test(password) && // checks for a-z
            // /[0-9]/.test(password) && // checks for 0-9
            // /\W|_/.test(password) && // checks for special char
            password.length >= 4
          )
        }
      },
      confirm_password: {
        required,
        sameAsPassword () {
          return this.form.password === this.form.confirm_password
        }
      }
    }
  },
  created () {
    this.form.token = this.$route.params.token
  },
  computed: {
    ...mapGetters(['isClientUser', 'isPartnerUser'])
  },
  methods: {
    submit () {
      this.resetMessage()
      this.v$.form.$touch()
      this.invalid = this.v$.form.$invalid
      if (!this.form.token) {
        this.showMessage('danger', 'No registration token, please check your registration link.')
      } else {
        if (this.invalid === false) {
          this.register()
        } else {
          this.showMessage('danger', 'The form has errors, please fix them.')
        }
      }
    },
    hasOnboardingClients (userId) {
      if (this.isClientUser) {
        this.$api.get('/client-users/'+userId+'/onboarding-clients')
          .then(response => {
            this.isLoading = false
            if (response.data) {
              this.$router.push('/onboarding')
            } else {
              window.location.href = 'https://platform.reunionmarketing.com'
            }
          }, (error) => {
            this.isLoading = false
            console.log(error)
          })
      } else if (this.isPartnerUser) {
        this.$api.get('/partner-users/'+userId+'/onboarding-clients')
          .then(response => {
            this.isLoading = false
            if (response.data) {
              this.$router.push('/onboarding')
            } else {
              this.$router.push('/seo-reporting-dashboard/clients')
            }
          }, (error) => {
            this.isLoading = false
            console.log(error)
          })
      } else {
        this.isLoading = false
      }
    },
    register () {
      this.isLoading = true
      this.$store.dispatch('register', this.form)
        .then((response) => {
          this.isLoading = false

          this.$store.dispatch('loginWhiteLabel', {
            email: this.form.email,
            password: this.form.password
          }).then(() => {
            if (response.data.user.user_type === 'client_user') {
              if (typeof response.data.user.id !== 'undefined') {
                this.hasOnboardingClients(response.data.user.id)
              }
            } else if (response.data.user.user_type === 'partner_user') {
              if (typeof response.data.user.id !== 'undefined') {
                this.hasOnboardingClients(response.data.user.id)
              }
            } else {
              this.$router.push('/')
            }
          })
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
          if (err.response.data && err.response.data.error && err.response.data.error !== 'Unauthorised') {
            if (err.response?.data?.error?.includes('already registered')) {
              this.showMessage('warning', err.response.data.error.replace('login', '<a href="/auth/login">login</a>'))
            } else {
              this.showMessage('danger', err.response.data.error)
            }
          } else {
            this.showMessage('danger', 'There was an error, unable to register in with those credentials.')
          }
        })
    },
    showMessage (type, msg) {
      this.displayMessage = msg
      this.messageType = type
    },
    resetMessage () {
      this.displayMessage = this.messageType = ''
    }
  }
}
</script>

<style lang="scss">

</style>
